.flex-main-3-thumbs-list-on-sidebar{
    .main-post{
        margin-bottom: 50px;
        h2{            
            @include font-size(34px);
            line-height: 40px;            
        }
        .img-wrapper{
            // height: 400px;
            overflow: hidden;
            img{
                object-fit: cover;
                width: 100%;
                height: 400px;
            }
        }           
    }
    .bottom-post{
        h2{
            @include font-size(17px);
        }
        .img-wrapper{
            width: 100%;
            overflow: hidden;
            margin-bottom: 10px;
            img{
                height: 169px;
                width: 100%;
                object-fit: cover;
            }
        }
    }
    .sidebar{
        width: 100%;
        @media (max-width: $xsmall) {
            margin-top: 50px;
        }
        h1,
        h2{
            margin-bottom: 20px;
        }        
        h2,
        h1{
            width: 100%;
            text-transform: uppercase;
            @include font-size(15px);
            margin-bottom: 25px;            
            &:after{
                margin-bottom: -10px;
                content: "";                
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 0;
                background: #000;
                height: 2px;
                margin-top: 2px;
                display: block;
            }
        }
        .post-wrapper{
            position: relative;
            margin-top: 15px;
            .cat{
                margin-bottom: 10px;
                @media (max-width: $xsmall) {
                    margin-bottom: 20px;
                }
            }
            &:first-of-type{
                margin-top: 0;
            }
            &:after{
                content: "";
                width: 100%;
                height: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: rgba(0,0,0,.3);
            }
            h2{
                @include font-size(15px);
                margin-bottom: 5px;
                margin-top: 0;
                &:after{
                    display: none;
                }
            }
            .cat{
                @include font-size(11px);
            }
        }
    }
}