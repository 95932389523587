.single-post{
    .single-wrapper{
        margin-top: 50px;
    }
    .main-content{
      figure{
        text-align: center;
        margin: 0;
      }
      img{
        cursor: pointer;
        width: auto;
        height: auto;
        max-width: 100%;
      }
      h2{
        @include font-size(22px);
      }
      h3{
          @include font-size(20px);
      }       
      p, li{
          @include font-size(18px);
      }           
      .twitter-tweet{
          // max-height: 500px;
          height: auto;
          margin: 0 auto;
      }
      
      p{
          line-height: 1.4;
      }
      a{
        display: inline-block;
        min-height: auto;
      }
      // iframe{
      //   height: 500px;
      //   width: 100%;
      // }   
      tr{
        height: 35px;
        td{
            vertical-align: middle;
            padding: 10px;
        }
      }
      em{
        @include font-size(14px);
      }
      figure{        
        margin: 50px 0!important;
        iframe{
          height: 500px;
          width: 100%;          
        }      
      }
    }
    .article-header{
      padding: 0;

      h1{
          @include font-size(40px);
          margin-bottom: 10px
      }
      p{
          @include font-size(18px);
          color: rgb(99, 99, 99);            
      }
      .author-wrapper{
          margin: 43px 0 0;
          span{
              color: rgb(99, 99, 99);
              margin: 5px 0;
              display: block;
          }
      }           
    }
    .img-wrapper{
        margin-bottom: 50px;
        img{
            width: 100%;
            height: auto;
            object-fit: cover;
            min-height: 200px;
        }        
    }
    
    .modal{
        opacity: 0;
        visibility: hidden;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0,0,0,.8);
        z-index: 999;
        transition: all .3s $ease1;
        display: flex;
        align-items: center;
        justify-content: center;
        &.active{
            opacity: 1;
            visibility: visible;
        }
        .img-wrapper{
            position: relative;
            margin-bottom: 0;
            max-height: 100%;
            .close{
                position: absolute;
                right: -40px;
                top: 0px;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                cursor: pointer;
                background: #000;
                span{
                    width: 100%;
                    height: 2px;
                    background: #fff;
                    position: absolute;
                    &:first-of-type{
                        transform: rotate(45deg);
                    }
                    &:last-of-type{
                        transform: rotate(-45deg);
                    }
                }
            }
        }        
    }    
  .share{
    margin-top: 20px;
    .social-icon{
      display: inline-block;
      margin-right: 10px;
      img{
        width: 30px;
      }      
    }
  }
}