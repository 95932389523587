.flex-main-3-thumbs-on-sidebar{    
    .sidebar{
        .post-wrapper{            
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            @media (max-width: $xsmall) {
                flex-direction: column;
                margin: 50px 0;
            }            
            h2{
                @include font-size(24px);
            }
            .img-wrapper{                                
                overflow: hidden;
                margin-right: 20px;
                margin-bottom: 20px;
                width: 100%;
                min-width: 180px;
                // max-height: 190px;
                @media (max-width: $xsmall) {
                    width: 100%;
                    margin-right: 0;
                }
                img{
                    width: inherit;                    
                    height: 100%;
                    min-width: 180px;
                    @media (max-width: $xsmall) {
                        width: 100%;
                        height: auto;
                    }
                    // width: 100%;
                    // height: 100%;                
                    // object-fit: cover;
                }
                .credits{
                    display: block;
                }
            }
            .content{
                width: 100%;
                padding-left: 35px;
            }
        }                
    }
}