.flex-module{    
    .post-wrapper{
        // .cat{
        //     margin-bottom: 10px;
        //     a{
        //         font-weight: 700;
        //         @include font-size(11px);                
        //         color: #155991;
        //         text-decoration: none;
        //         text-transform: uppercase;
        //     }
        // }
        a{
            width: 100%;
        }
        h2{
            margin-bottom: 10px
        }
        
        .excerpt{
            font-weight: bold;
            color: rgb(117, 117, 117);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
            -webkit-box-orient: vertical;
        }
        &.main-post{
            .img-wrapper{
                img{
                    height: auto;
                }
            }            
        }
        .img-wrapper{
            
            // width: 180px;
            // min-width: 180px;
            // height: 180px;
            // overflow: hidden;
            // margin-right: 20px;
            img{
                // width: 100%;
                // height: 100%;                
                object-fit: contain;
            }
        }
    }
    .main-post{
        h2{
            @include font-size(34px) ;            
        }
        img{
            width: 100%;
            max-height: 400px;
            height: 400px;
            object-fit: cover;
            object-position: top;
        }
        .content{
            margin-top: 10px;
        }
    }
    .bottom-post{
        @media (max-width: $xsmall) {
            margin-bottom: 50px;
        }
    }

    .sidebar{     
        .post-wrapper{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;            
        }                
    }
    
}
