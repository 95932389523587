.post-list{
    .post-wrapper{
        display: flex;
        width: 100%;
        height: auto;
        margin: 10px 0;
        @media (max-width:$xsmall) {
            flex-direction: column;
            margin-bottom: 50px;
        }
        .img-wrapper{
            margin-right: 20px;
            width: 300px;
            height: auto;
            margin-bottom: 0;
            @media (max-width:$xsmall) {
                margin-right: 0;
                width: 100%;
            }
            img{
                width: 100%;
                height: auto;
                object-fit: contain;
                object-position: top;
                position: relative;
                @media (max-width: $xsmall) {
                    width: 100%;
                }
            }
        }
        .content{
            h2{
                @include font-size(34px);
            }
            p{
                @include font-size(18px);
                color: #757575;
                
            }
        }
    }
    .wp-block-columns{
        flex-wrap: wrap!important; 
    }
}