.flex-main-grid-no-sidebar{
    .row-wrapper{
        margin-bottom: 50px;
        .post-wrapper{            
            &.main-post{
                .img-wrapper{
                    // height: 300px;
                    margin-bottom: 10px;
                    img{
                        height: 300px;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
        }        
        &:last-of-type{
            margin-bottom: 0;   
        }
    }
}