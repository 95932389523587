*,
*:before,
*:after {
  box-sizing: border-box;
}

@mixin width($value) {
  @include rfs($value, width);
}

@mixin height($value) {
  @include rfs($value, height);
}

@mixin top($value) {
  @include rfs($value, top);
}

@mixin bottom($value) {
  @include rfs($value, bottom);
}

@mixin left($value) {
  @include rfs($value, left);
}

@mixin right($value) {
  @include rfs($value, right);
}
//MAX—SCREEN
$xxlarge: 2560px; // --- iMac 27' (.ie) and other large desktops ---
$xlarge: 1919px; // --- MacBook Pro 15'(.ie) ---
$large: 1439px; // --- MacBook Pro 13'(.ie) ---
// $largeMax: 1370px;
$medium: 1370px; // --- iPad landscape ---
$small: 1023px;
$xsmall: 767px;
$xxsmall: 361px;

$col_number: 12;
$gutter: 1.15;
$max_width: 85;
$row_margin: 40px;

$col_width: 100 / $col_number; 