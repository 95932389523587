$ease1: cubic-bezier(.075,.82,.165,1);
$black: #1B1B21;
/* montserrat-300 - latin */
// @font-face {
//     font-family: 'Montserrat';
//     // font-style: normal;
//     // font-weight: 300; 
//     src: url('../fonts/montserrat-v25-latin-300.eot');
//     src: local(''),
//         //  url('../fonts/montserrat-v25-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//          url('../fonts/montserrat-v25-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
//         //  url('../fonts/montserrat-v25-latin-300.woff') format('woff'), /* Modern Browsers */
//         //  url('../fonts/montserrat-v25-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
//         //  url('../fonts/montserrat-v25-latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
//   }
  
  /* montserrat-500 - latin */
  @font-face {  
    font-family: 'Montserrat';  
    font-style: normal;
    // font-weight: 500;
    src: url('../fonts/montserrat-v25-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
        //  url('../fonts/montserrat-v25-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v25-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
        //  url('../fonts/montserrat-v25-latin-500.woff') format('woff'), /* Modern Browsers */
        //  url('../fonts/montserrat-v25-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
        //  url('../fonts/montserrat-v25-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* /* montserrat-600 - latin */
  // @font-face { 
  //   font-family: 'Montserrat';
  //   font-style: normal;
  //   font-weight: 600; 
  //   src: url('../fonts/montserrat-v25-latin-600.eot'); /* IE9 Compat Modes */
  //   src: local(''),
  //        url('../fonts/montserrat-v25-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  //        url('../fonts/montserrat-v25-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  //        url('../fonts/montserrat-v25-latin-600.woff') format('woff'), /* Modern Browsers */
  //        url('../fonts/montserrat-v25-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  //        url('../fonts/montserrat-v25-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
  // }
  // /* montserrat-700 - latin */
  // @font-face {
  //   font-family: 'Montserrat';
  //   font-style: normal;
  //   font-weight: 700;
  //   src: url('../fonts/montserrat-v25-latin-700.eot'); /* IE9 Compat Modes */
  //   src: local(''),
  //        url('../fonts/montserrat-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  //        url('../fonts/montserrat-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  //        url('../fonts/montserrat-v25-latin-700.woff') format('woff'), /* Modern Browsers */
  //        url('../fonts/montserrat-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  //        url('../fonts/montserrat-v25-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
  // }
// @import "utils/reset";
@import "~rfs/scss"; 
@import '~sass-mediaqueries/_media-queries';
@import 'grid/grid.scss';  

@import 'components/**/*.scss'; 