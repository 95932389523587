html{
    width: 100%;
}
body{
    overflow-x: hidden;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;    
    font-size: 16px;
    // -webkit-transform: translateZ(0);
    // -webkit-backface-visibility: hidden;
}

h1, h2, h3, h4, h5, p{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: auto;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
}
p{
    line-height: 1.4;
    margin: 0px 0 20px;
}
a{
    text-decoration: none;
    color: #155991;
    @media (max-width:$xsmall) {
        min-width: 48px; 
        min-height: 48px;
        position: relative;        
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
    }
}

h1{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    @include font-size(40px);
    line-height: 1.2em;
    color: $black;
    position: relative;
    display: inline-block;
    @media (max-width: $xsmall) {
        font-size: 32px;
    }    
}
h2{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    // @include font-size(92px);
    line-height: 1.2em;
    color: $black;
    margin: 20px 0;
    position: relative;
    display: inline-block;      
    transition: all .3s cubic-bezier(0.39, 0.575, 0.565, 1);  
    @media (max-width: $xsmall) {
        margin: 0;
    }
}
h3{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    @include font-size(72px);
    line-height: 1.8em;
    color: $black;
    position: relative;
}
h4{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: light;
    @include font-size(72px);
    line-height: 1.4em;
    color: $black;
    position: relative;
}
h5{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    @include font-size(56px);
    line-height: 1.2em;
    color: $black;
    position: relative;
    @media (max-width: $xsmall) {
        font-size: 24px;
        line-height: 1;
    }   
}
h6{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: light;
    @include font-size(56px);
    line-height: 84px;
    color: $black;
}
ul{
    list-style: disc;
    list-style-position: inside;
    padding-left: 20px;
    li{
        margin-bottom: 10px;
        line-height: 1.3em;
    }
}

strong{
    font-weight: bold;
}

span{
    // font-family: 'Poppins', sans-serif;
    font-weight: bold;    
}
section{
    padding: 50px 0;
}
.hide-small{
    display: inherit;
    @media (max-width: $small){
        display: none;
    }
}
.show-small{
    display: none;
    @media (max-width: $small){
        display: block;
    }
}

.load-screen{
    display: none;
    img{        
        width: 50px;
        height: 50px;
        animation:spin 1s linear infinite;
    }
}

.header-ad-wrapper{
    height: 255px;    
    position: relative;
    top:0;
    background-color: white;
    z-index: 3;
    transition: all .3s $ease1;
    @media (max-width: 970px) { 
        display: none; 
    }
    .header-ad{
        display: flex;
        justify-content: center;
        z-index: 3;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 250px;
        margin: 0 auto;
        width: 100%;
        background-color: white;

        .pubg-ad{
            background-color: #eaeaea;
            // height: 225px;
        }
    }
    
}
.header-ad-wrapper-mobile{
    height: 62px;
    display: none;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 10;
    transition: all .3s $ease1;
    z-index: 9991;
    @media (max-width: 970px) {
        display: block;        
    }
    .header-ad-mobile{
        height: 62px;
        display: flex;
        justify-content: center;
        z-index: 3;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

//SIDEBAR HIDEN MOBILE

.brid-default-skin{
    // height: 326.215px!important;
    @media (max-width: $small) {
        // height: 480px!important;
        margin: 0 auto!important;
    }
    &>div{
        background-color: #fff!important;
    }
}
.sidebar-ad{
    width: 300px;
    height: 250px;
    z-index: 1;
    display: block;
    position: relative;
    // background-color: #e5e5e5;
    margin: 0 auto;
    @media (max-width: $small) {
        display: none;
    }
    &.brid-container{
        height: inherit!important;
        z-index: 2;
        width: 300px;
        margin: 0 auto;
        @media (max-width: $small) {
            width: 100%;
            // height: 480px!important;            
            display: block;
            margin-top: 50px;
        }
    }
    img{
        width: 100%;
    }
}
.main-header{
    transition: all .3s $ease1;
}
.add-block,
.main-block-ad{
    margin: 4rem 0;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    .pubg-ad{
        // height: 100%;
        display: flex;
        align-items: center;
        background-color: #e5e5e5;
    }
}

.cat{
    // margin-bottom: 10px;    
    a{
        transition: all .3s cubic-bezier(0.39, 0.575, 0.565, 1); 
        font-weight: bold;
        @include font-size(14px);                
        color: #155991;
        text-decoration: none;
        text-transform: uppercase;
        @media (max-width: $xsmall) {
            @include font-size(15px);            
        }
    }
    :hover{
        a{
            color: #000;
        }
    }
}
.credits{
    @include font-size(10px);
}

.pubg-ad > div{
    width: 100%;
    height: 100%;
} 
.single-post .pubg-ad iframe{
    // height: auto!important;
}

.main-cat-name{        
    @media (max-width:$xsmall) {
        margin-bottom: 25px;
    }
    &:after{
        content: "";
        height: 5px;
        width: 100%;
        position: relative;
        background-color: #155991;
        margin-top: 20px;
        margin-bottom: 50px;
        @media (max-width: $xsmall) {
            margin-bottom: 0;
        }
    }
    h2,h1{
        @include font-size(64px);
        color:#155991;
        font-weight: bold;
    }        
}

.post-wrapper:hover{
    h2{
        color: #155991;
    }
}

.wp-block-columns{
    height: auto;
}

.wp-block-image{
    margin: 1em 0 1em!important;
    figcaption{
        @include font-size(12px);
    }
}

.archive{
    &.category{
        .main-cat-name{
            margin-top: 50px;
        }
    }
}

.error404{
    .fourofour{
        text-align: center;
    }    
    
}

.wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column{
    @media (max-width: $small) {
        flex-basis: inherit!important;
        flex-grow: inherit!important;
    }       
}