
nav.nav-mobile{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: white;
    transform: translateX(100%);
    transition: all .3s cubic-bezier(0.39, 0.575, 0.565, 1);
    @media (max-width: 970px) {
        display: block;
    }
    &.active{
        transform: translateX(0);
        z-index: 999;
    }
    .header-menu{
        height: 100%;
        .menu{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .menu-item{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;  
                margin: 10px 0;                          
                cursor: pointer;
                a{
                    font-weight: bold;
                    color:#155991;
                    text-decoration: none;
                    display: inline-block;
                    white-space: nowrap;
                    margin-bottom: 10px;
                    @include font-size(20px)
                }            
                
                .sub-menu{
                    display: flex;
                    flex-direction: column;
                    .menu-item{
                        margin: 0;
                        a{
                            @include font-size(15px)
                        }
                    }
                }
            }
        }
    }
}
header{
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;    
    // background: rgb(255,255,255);
    // background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 5%, rgba(0,0,0,1) 24%);
    color: #000 ;    
    transition: all .3s $ease1;
    z-index: 999;    
    .logo-wrapper{
        display: inline-block;        
        height: 100%;
    }
    .mobile-header{
        height: 62px;
    }
    .desktop-header{
      height: 80px;
      // .row{
      //   max-height: 100%;
      //   .columns{
      //     max-height: 100%;
      //   }
      // }      
    }
    .desktop-header,
    .mobile-header{
        background: #fff;
        // position: sticky;
        // top: 0;
        // display: flex;
        // align-items: center;
        // width: 100%;
    }

    @media (max-width: 970px) {
        // position: fixed;
    }
    
    &.active{
        position: fixed;
        top: 0;
        height: 50px;
        -webkit-box-shadow: -1px 5px 20px -4px rgba(0,0,0,0.27);
        -moz-box-shadow: -1px 5px 20px -4px rgba(0,0,0,0.27);
        box-shadow: -1px 5px 20px -4px rgba(0,0,0,0.27);
        .logo{
            width: 150px;                        
        }

        &+div{
            padding-top: 80px;
        }
    }
    .desktop-header,
    .mobile-header{
        width: 100%;
    }
    .row{
        width: 100%;
        height: 100%;
        align-items: center;
        .columns{
            position: relative;
        }
    }
    .logo{
      height: 100%;
      width: 280px;
      object-fit: contain;
      transition: all .3s $ease1;
      z-index: 2;
      display: block;
      position: relative;
      @media (max-width: $xsmall) {
          width: 200px;
      }
    }
    .desktop-header{
        display: flex;
        @media (max-width: 970px) {
            display: none;
        }
    }
    .mobile-header{
        display: none;
        @media (max-width: 970px) {
            display: flex;
        }
    }
    nav.nav-desktop{        
        display: flex; 
        justify-content: flex-end;        
        color: #000;
        @media (max-width: 970px) {
            display: none;
        }
        .menu{
            position: relative;
            .menu-item{
                position: relative;
                display: inline-block;
                margin: 0 20px;
                // padding: 30px 0;
                cursor: pointer;
                a{
                    font-weight: bold;
                    color:#000;
                    text-decoration: none;
                    display: inline-block;
                    white-space: nowrap;
                }
                &:hover{
                    .sub-menu{
                        display: block;
                    }
                }
            }
        }
        .sub-menu{
            display: none;
            position: absolute;
            // margin-top: 30px;           
            background: #fff;
            padding: 20px 10px;
            min-width: 100%;
            .menu-item{
                padding: 0;
                text-align: center;
                margin: 10px 0;
                width: 100%;
            }
        }
        
    }    
    .burguer{
        // display: none;
        position: relative;
        right: 0;
        // top: -3px;
        width: 30px;
        height: 30px;
        margin: auto 0 auto auto;
        z-index: 2;
        @media (max-width: 970px) {
            display: block;            
        }

        .menu-button-container {
            // display: none;
            height: 30px;
            width: 30px;
            cursor: pointer;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            display: block;
        }

          
        #menu-toggle {
            display: none;
        }
          
        .menu-button,
        .menu-button::before,
        .menu-button::after {
            display: block;
            background-color: #155991;
            position: absolute;
            height: 4px;
            width: 30px;
            transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
            border-radius: 2px;
            top: 15px;
        }
          
        .menu-button::before {
            content: '';
            margin-top: -25px;
        }
          
        .menu-button::after {
            content: '';
            margin-top: -5px;
        }
          
        #menu-toggle:checked + .menu-button-container .menu-button::before {
            margin-top: -15px;
            transform: rotate(405deg);
        }
          
        #menu-toggle:checked + .menu-button-container .menu-button {
            background: rgba(255, 255, 255, 0);
        }
          
        #menu-toggle:checked + .menu-button-container .menu-button::after {
            margin-top: -15px;
            transform: rotate(-405deg);
        }
          
    }
}
.breadcrumbs{
    padding-top: 50px;
    p,
    span,
    a{
        font-family: 'Montserrat', sans-serif;
        font-style: normal;    
        font-size: 16px;
        font-weight: bold;                
    }    
    &>span{
        padding: 0 0;
    }
    span a{
        padding: 0 10px!important;
    }
    a{
        color: #155991;
        @media (max-width: $xsmall) {
            min-width: 48px;
            min-height: 48px;
            position: relative;
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;
            padding: 0 10px;
            &:first-of-type{
                padding: 0;
            }            
        }
    }
}