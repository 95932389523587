footer{
    position: relative;
    min-height: 400px;
    // background-color: #155991;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    .socials{
        width: 100%;
        display: flex;
        justify-content: center;
        .social-wrapper{
            display: inline-flex;
            margin: 0 10px;
            transition: all .3s $ease1;
            a{
                @media (max-width: $xsmall) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            &:hover{
                transform: scale(1.1);
            }
            i{
                font-size: 20px;
                color: #155991;
            }
        }        
    }
    nav{
        text-align: center;
        margin-top: 50px;
        ul{
            list-style: none;
            list-style-position: outside;
            padding-left: 0;
        }
        a{            
            font-weight: bold;
            color:#000;
            text-decoration: none;
            display: inline-block;
            white-space: nowrap;        
        }
    }
    .footer-logo{
        width: 450px;
        @media (max-width: $xsmall) {
            width: 100%;
        }
    }
    .copy{
        text-align: center;
        margin-top: 50px;
    }
}